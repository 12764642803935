.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

body, footer {
	background-color: #282c34;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

footer {
	position: fixed;
	left: 0;
  	bottom: 0;
  	width: 100%;
  	color: white;
  	text-align: center;
}

.App-link {
  	color: #09d3ac;
}

#home_main_logo {
	height: 500px;
}


/* For Mobile */
@media screen and (max-width: 540px) {
	#home_main_logo {
		max-width: 100% !important;
	}
}

/* For Tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
	#home_main_logo {
		max-width: 90% !important;
	}
}


